import loadable from "@loadable/component";
import { useMotionValue } from "framer-motion";
import { graphql } from "gatsby";
import React, { useEffect, useMemo, useState, useRef } from "react";
import Cookie from "../components/cookie/Cookie";
import Info from "../components/info/Info";
import Loading from "../components/loading/Loading";
import Menu from "../components/menu/Menu";
import Rule from "../components/header/Rule";
import Header from "../components/header/Header";
import { useWindowSize } from "../hooks/useWindowSize";
import * as style from "../styles/index.module.css";
import { useFilterContext } from "../state/GlobalState";
const Grid = loadable(() => import("../components/grid/Grid"));

const Index = ({
	data: {
		allSanityProject: { projects },
	},
}) => {
	const hasWindow = typeof window !== "undefined";
	const mql = useMemo(() => {
		if (hasWindow) return window.matchMedia("(max-width: 799px)");
	}, [hasWindow]);
	const { windowHeight } = useWindowSize();
	const [menuVisibility, setMenuVisibility] = useState(null);
	const [mobile, setMobile] = useState(mql?.matches || null);
	const [infoHeight, setInfoHeight] = useState(0);
	const cookieY = useMotionValue(infoHeight);
	const masonryRef = useRef(null);
	const FilterContext = useFilterContext()

	useEffect(() => {
	}, [FilterContext])

	useEffect(() => {
		cookieY.set(infoHeight * -1);
	}, [infoHeight, cookieY]);

	useEffect(() => {
		if (!mobile) {
			setMenuVisibility(true);
		} else {
			setMenuVisibility(false);
		}
	}, [mobile]);

	useEffect(() => {
		const resize = () => setMobile(mql.matches);
		try {
			mql.addEventListener("change", resize);
			resize();
		} catch (e1) {
			try {
				mql.addListener((e) => resize);
				resize();
			} catch (e2) {
				console.error(e2);
			}
		}
		return () => {
			try {
				mql.removeEventListener("change", resize);
			} catch (e1) {
				try {
					mql.removeListener("change", resize);
				} catch (e2) {
					console.error(e2);
				}
			}
		};
	}, [mql]);

	return (
		<div className={style.index} style={{ height: `${windowHeight}px` }} id="page-container">
			<Header
				menuVisibility={menuVisibility}
				setMenuVisibility={setMenuVisibility}
				mobile={mobile}
			/>
			<Rule />
			<div className={style.content}>
				<Menu
					menuVisibility={menuVisibility}
					setMenuVisibility={setMenuVisibility}
					mobile={mobile}
				/>
				<Grid fallback={<Loading />} mobile={mobile} ref={masonryRef} />
			</div>
			<Cookie cookieY={cookieY} />
			{projects.map((project) => (
				<Info key={project._id} setInfoHeight={setInfoHeight} data={project} />
			))}
		</div>
	);
};

export default Index;

export const query = graphql`
	query ProjectQuery {
		allSanityProject {
			projects: nodes {
				_id
				_rawAbout
				_rawCredit
				title
				date
				client {
					title
				}
			}
		}
	}
`;
