import React from "react";
import Arrow from "../icons/Arrow";
import * as style from "./menu.module.css";

const Websites = ({ title, contents }) => {
	return (
		<div>
			<h2 className={style.title}>{title}</h2>
			<br />
			<div className={style.interactive}>
				{contents.map((content) => {
					return content.visible === false ? null : (
						<a href={`${content.link}`} target="_blank" rel="noreferrer">
							{content.title}
							<Arrow margin={5} />
						</a>
					);
				})}
			</div>
		</div>
	);
};

export default Websites;