import React, { useState, useEffect } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { motion, useSpring } from "framer-motion";
import * as style from "./cookie.module.css";
import Pill from "../pill/Pill"

const variants = {
    open: { rotate: 0, x: 0, display: "block" },
    closed: { rotate: -90, x: -40, transitionEnd: { display: "none" } },
};

const Cookie = ({ cookieY }) => {
    const [cookie, setCookie] = useState(false);

    useEffect(() => {
        const value = getCookieConsentValue();
        let timer = setTimeout(() => {
            if (value === undefined) {
                setCookie(true);
            }
        }, 2000);
        return () => clearTimeout(timer);
    }, [setCookie]);

    const optOut = () => {
        document.cookie = `ga-disable-${process.env.GATSBY_GOOGLE_TRACKING_ID}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC;path=/`;
        window[`ga-disable-${process.env.GATSBY_GOOGLE_TRACKING_ID}`] = true;
        window.disableStr = 1;
    };

    const dy = useSpring(cookieY, { damping: 10 });

    return (
        <motion.div className={style.cookie} style={{ y: dy }}>
            <motion.div
                className={style.wrapper}
                animate={cookie ? "open" : "closed"}
                transition={{ type: "spring", bounce: 0.5, duration: 1.5 }}
                initial="closed"
                variants={variants}
            >
                <CookieConsent
                    disableStyles={true}
                    containerClasses={style.consent}
                    buttonClasses={style.accept}
                    buttonText={"I accept"}
                    declineButtonClasses={style.decline}
                    declineButtonText={<Pill type={"pseudobutton"} />}
                    enableDeclineButton
                    hideOnAccept={false}
                    onAccept={() => setCookie(false)}
                    onDecline={() => {
                        setCookie(false);
                        optOut();
                    }}
                    flipButtons={false}
                    debug={false}
                >
                    By clicking on ‘I accept’ or continuing to browse on this website, you accept
                    the use of cookies which are used to analyse website traffic.
                </CookieConsent>
            </motion.div>
        </motion.div>
    );
};

export default Cookie;

export const DeclineButton = () => {
    return (
        <>
            <span className={style.lineLeft} />
            <span className={style.lineRight} />
        </>
    );
};
