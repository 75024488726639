import React from 'react'
import * as style from "./loading.module.css"

const points = 8;
const radius = 20;

const Loading = () => {
  return (
		<div className={style.loading}>
			<div className={style.loader} >
				<div className={style.wrapper}>
					{Array(points)
						.fill()
						.map((v, index) => {
							const x = radius * Math.cos((2 * Math.PI * index) / points);
							const y = radius * Math.sin((2 * Math.PI * index) / points);
							return (
								<span
									key={index}
									className={style.dot}
									style={{ transform: `translate(${x}px, ${y}px)` }}
								/>
							);
						})}
				</div>
			</div>
		</div>
  );
}

export default Loading