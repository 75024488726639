import { PortableText } from "@portabletext/react";
import React from "react";
import * as style from "./menu.module.css";

const Static = ({ title, content }) => {
	return (
		<div className={style.basic}>
			<h2 className={style.title}>{title}</h2>
			<br />
			<h3>
				<PortableText value={content} />
			</h3>
		</div>
	);
};

export default Static;
