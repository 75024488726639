import { useEffect, useState } from "react";

export const useWindowSize = () => {
    const [size, setSize] = useState({ windowWidth: 0, windowHeight: 0 });
    useEffect(() => {
        const handleResize = () => setSize({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return size;
};
