import React, { useRef, useEffect, useState } from "react";
import * as style from "./info.module.css";
import { motion, AnimatePresence, useDragControls } from "framer-motion";
import { useInfoActiveContext, useInfoActiveUpdateContext } from "../../state/GlobalState";
import { PortableText } from "@portabletext/react";
import Pill from "../pill/Pill";
import { nanoid } from "nanoid";

const Info = ({ data, setInfoHeight }) => {
	const InfoActiveContext = useInfoActiveContext();
	const InfoActiveUpdateContext = useInfoActiveUpdateContext();
	const [fade, setFade] = useState(false);
	const ref = useRef(null);
	const dragControls = useDragControls();

	useEffect(() => {
		if (InfoActiveContext.project === data._id) {
			setInfoHeight(ref.current.offsetHeight);
		}
		if (InfoActiveContext.project === "") {
			setInfoHeight(0);
		}
	}, [InfoActiveContext, data, setInfoHeight]);

	const onDragStart = (event, info) => {
		info.offset.y < 0 && setFade(true);
	};

	const onDragEnd = (event, info) => {
		setFade(false);
		if (info.offset.y > 50 && info.velocity.y >= 0) {
			InfoActiveUpdateContext({ post: "", project: "" });
		}
	};

	return (
		<AnimatePresence>
			{InfoActiveContext.project === data._id && (
				<motion.div
					className={style.info}
					initial={{ y: "100%", zIndex: 99 }}
					animate={{ y: "0%", zIndex: 100 }}
					exit={{
						y: "100%",
						zIndex: 99,
						transition: { type: "tween", duration: 0.2 },
					}}
					ref={ref}
					drag={"y"}
					dragConstraints={{ left: 0 }}
					dragElastic={0.5}
					onDragEnd={onDragEnd}
					dragSnapToOrigin={true}
					onDragStart={onDragStart}
					dragControls={dragControls}
					dragListener={false}
				>
					<div className={style.controls}>
						<Pill
							type={"button"}
							func={() => InfoActiveUpdateContext({ post: "", project: "" })}
							invert
						/>
						<div
							className={style.dragger}
							onPointerDown={(e) => {
								dragControls.start(e);
							}}
						></div>
					</div>
					<div className={style.details}>
						<Pill title={data.title} weight={500} />
						<Pill title={data.date} weight={400} />
						{data.client.map(
							(client) =>
								client.title !== "Other" && (
									<Pill title={client.title} weight={400} key={nanoid()} />
								)
						)}
						{InfoActiveContext.subtitle && (
							<Pill title={InfoActiveContext.subtitle} weight={400} />
						)}
					</div>

					<div className={style.wrapper} key={data._id}>
						{data._rawAbout && (
							<div className={style.text}>
								<PortableText value={data._rawAbout} />
							</div>
						)}
						
							{data._rawCredit && (
								<div className={style.credit}>
									<PortableText value={data._rawCredit} />
								</div>
							)}
					</div>
					<div className={style.fade} style={{ opacity: fade ? 1 : 0 }}></div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default Info;
