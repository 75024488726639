import { motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { useCurrentContext, useCurrentUpdateContext } from "../../state/GlobalState";
import Basic from "./Basic";
import Interactive from "./Interactive";
import * as style from "./menu.module.css";
import Websites from "./Websites";

const Menu = ({ menuVisibility, setMenuVisibility, mobile }) => {
	const { info, clients, categories, websites } = useStaticQuery(getData);
	const CurrentContext = useCurrentContext();
	const CurrentUpdateContext = useCurrentUpdateContext();

	const variants = {
		visible: { x: 0 },
		hidden: { x: "100%" },
	};

	const variantsBackground = {
		visible: {
			visibility: "visible",
			boxShadow: "-10px 0px 10px -10px var(--shadow-colour)",
		},
		hidden: {
			boxShadow: "-10px 0px 10px -10px #ffffff00",
			transitionEnd: { visibility: "hidden" },
		},
	};

	const onDragEnd = (event, info) => {
		if (info.offset.x < 100 && info.velocity.x <= 0) {
			setMenuVisibility(true);
		} else {
			setMenuVisibility(false);
		}
	};

	return (
		<>
			{menuVisibility !== null && (
				<motion.nav
					className={style.menu}
					style={{ position: mobile ? "fixed" : "sticky" }}
					variants={variants}
					animate={menuVisibility ? "visible" : "hidden"}
					initial={mobile ? "hidden" : "visible"}
					drag={"x"}
					dragConstraints={{ left: 0 }}
					dragElastic={0.5}
					onDragEnd={onDragEnd}
					dragSnapToOrigin={true}
				>
					<div className={style.container}>
						<Basic title={"Info"} content={info._rawInfo} />
						<Interactive
							title={"Categories"}
							contents={categories.nodes}
							state={CurrentContext}
							setState={CurrentUpdateContext}
						/>
						<Interactive
							title={"Clients"}
							contents={clients.nodes}
							state={CurrentContext}
							setState={CurrentUpdateContext}
						/>
						<Websites title={"Websites"} contents={websites.nodes} />
					</div>
					<motion.div
						variants={variantsBackground}
						className={style.background}
						animate={menuVisibility && mobile ? "visible" : "hidden"}
						initial={mobile ? "hidden" : "visible"}
					></motion.div>
				</motion.nav>
			)}
		</>
	);
};

export default Menu;

const getData = graphql`
	{
		info: sanityInfo {
			_rawInfo
		}
		categories: allSanityCategory(sort: { fields: orderRank, order: ASC }) {
			nodes {
				_id
				orderRank
				visible
				title
				slug {
					current
				}
			}
		}
		clients: allSanityClient(sort: { fields: orderRank, order: ASC }) {
			nodes {
				_id
				orderRank
				visible
				title
				slug {
					current
				}
			}
		}
		websites: allSanityWebsite(sort: { fields: orderRank, order: ASC }) {
			nodes {
				_id
				orderRank
				visible
				title
				link
			}
		}
	}
`;
