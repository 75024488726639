import React, { useState } from "react";
import * as style from "./menubutton.module.css";
import { motion } from "framer-motion";
import {
	usePreviewUpdateContext,
	useFilterContext,
	useFilterUpdateContext,
} from "../../state/GlobalState";

const variants = {
	active: { scale: 1 },
	inactive: { scale: 0 },
};

const MenuButton = ({ title, slug, state }) => {
	const [hover, setHover] = useState(false);

	const PreviewUpdateContext = usePreviewUpdateContext();
	const FilterContext = useFilterContext();
	const FilterUpdateContext = useFilterUpdateContext();

	const highlighted = FilterContext.includes(slug);
	const muted = !highlighted && FilterContext.length > 0;
	const active = state.includes(slug) || (hover && !muted) || highlighted;

	return (
		<button
			onMouseEnter={() => {
				setHover(true);
				PreviewUpdateContext(slug);
			}}
			onMouseLeave={() => {
				setHover(false);
				PreviewUpdateContext("");
			}}
			onClick={() => {
				const pageContainer = document.getElementById("page-container");
				if (FilterContext.includes(slug)) {
					FilterUpdateContext(FilterContext.filter((item) => item !== slug));
				} else {
					FilterUpdateContext((prevArray) => [...prevArray, slug]);
				}
				pageContainer.scrollTo({ top: 0 });
			}}
			style={{ opacity: muted ? 0.25 : 1 }}
		>
			<motion.span
				className={style.dot}
				variants={variants}
				animate={active ? "active" : "inactive"}
				initial={"inactive"}
			>
				<svg width="5" height="5" viewBox="0 0 5 5">
					<circle
						cx="2.5"
						cy="2.5"
						r="2"
						fill="var(--text-colour)"
						stroke="var(--text-colour)"
						strokeMiterlimit="10"
					/>
				</svg>
			</motion.span>
			{title}
		</button>
	);
};

export default MenuButton;
