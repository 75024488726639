import React from "react";
import * as style from "./menu.module.css";
import MenuButton from "./MenuButton";

const Sticky = ({ title, contents, state, setState }) => {
	return (
		<div>
			<h2 className={style.title}>{title}</h2>
			<br />
			<div className={style.interactive}>
				{contents.map((content) => {
					return content.visible === false ? null : (
						<MenuButton
							key={content._id}
							title={content.title}
							slug={content.slug.current}
							state={state}
							setState={setState}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default Sticky;
